* {
    box-sizing: border-box;
  }
  
  body {
    background: linear-gradient(to right, #2334cf, #d3d3d3);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    margin: 0;
  }
  
  .container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 20px; /* Adiciona padding lateral */
  }
  
  .box {
    width: 100%;
    max-width: 400px; /* Largura máxima para telas maiores */
    background-color: rgba(255, 255, 255, 0.9);
    padding: 50px;
    border-radius: 20px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
    text-align: center;
  }
  
  .title {
    font-size: 1.5rem;
    color: #0072ff;
    margin-bottom: 40px;
  }

  .title-ordem {
    font-size: 1.5rem;
    color: #0072ff;
    margin-bottom: 1px;
  }

  .subtitle {
    font-size: 1rem;
    color: #0072ff;
    margin-bottom: 20px;
  }
  
  .input-group {
    margin-bottom: 20px;
    text-align: center; /* Centraliza o label e o input */
  }
  
  .input-group input {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
    border: 1px solid #ccc;
    font-size: 1rem;
    text-align: center; /* Centraliza o texto do input */
    transition: 0.3s;
  }
  
  .input-group input:focus {
    outline: none;
    border-color: #0072ff;
    box-shadow: 0 0 8px rgba(0, 114, 255, 0.4);
  }
  
  .button {
    width: 100%;
    padding: 12px;
    background-color: #0072ff;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: background-color 0.5s;
  }

  .button-cadastrar {
    width: 46%;
    padding: 1px;
    background-color: #0072ff;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.5s;
  }

  .button-esqueci {
    width: 46%;
    padding: 5px;
    background-color: #0072ff;
    color: white;
    border: none;
    border-radius: 10px;
    font-size: 0.8rem;
    cursor: pointer;
    transition: background-color 0.5s;
  }
  
  .button:hover {
    background-color: #005bb5;
  }
  
  .footer-links {
    margin-top: 20px; /* Espaçamento entre o botão de login e os links */
  }
  
  .footer-links a {
    color: #0072ff; /* Cor dos links */
    text-decoration: none; /* Remove sublinhado */
    font-size: 1rem; /* Ajuste o tamanho da fonte */
  }
  
  .footer-links a:hover {
    text-decoration: underline; /* Adiciona sublinhado ao passar o mouse */
  }

  .equipment-list {
    list-style-type: none; /* Remove a bolinha (bullet) */
    padding: 0; /* Remove o espaçamento à esquerda */
  }
  
  .equipment-item {
    margin-bottom: 15px;
  }
   
  .equipment-details {
    text-align: left;
    margin-top: 10px;
    padding: 10px;
    background-color: #f9f9f9;
    border-left: 5px solid #4CAF50;
    border-radius: 5px;
  }
  
.error {
  color: red; 
  font-weight: 500;
  margin-bottom: 20px;
  text-align: center;
}

.order-list {
  list-style-type: none;
  padding: 0;
}

.order-item {
  margin-bottom: 10px;
}

.order-item button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
  width: 100%;
  text-align: center;
}

.order-item button:hover {
  background-color: #45a049;
}

.order-details {
  text-align: left;
  margin-top: 0px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.order-details h3 {
  margin-bottom: 15px;
}

.order-details p {
  margin-bottom: 10px;
}

.order-details strong {
  font-weight: bold;
}

.back-button{
  margin-top: 100px;
  width: 25%;
  padding: 3px;
  background-color: #0072ff;
  color: white;
  border: none;
  border-radius: 10px;
  font-size: 0.8rem;
  cursor: pointer;
  transition: background-color 0.5s;
}

.subtitle .line {
  display: block;
  text-align: left;
}

.no-equipment-message {
  color: #ff0000; /* Vermelho para destaque */
  font-style: bold;
  text-align: center;
  margin: 50px 0;
}

.admin-dashboard {
  display: flex;
  flex-direction: column;
  font-family: Arial, sans-serif;
  min-height: 100vh; /* Garante altura mínima da tela */
  overflow-x: hidden; /* Evita rolagem horizontal */
}

.sidebar {
  width: 100%;
  background-color: #333;
  color: white;
  padding: 20px;
  text-align: center;
}

@media (min-width: 768px) {
  .admin-dashboard {
    flex-direction: row;
  }

  .sidebar {
    width: 200px;
    text-align: left;
  }
}

.sidebar h2 {
  color: #fff;
  margin-bottom: 20px;
}

.sidebar ul {
  list-style-type: none;
  padding: 0;
}

.sidebar li {
  padding: 10px 0;
  cursor: pointer;
  color: #ccc;
}

.sidebar li:hover {
  color: white;
}

.dashboard-content {
  flex: 1;
  padding: 20px;
  background-color: #f4f4f9;
  overflow-y: auto; /* Adiciona rolagem caso necessário */
}

/* Reset básico */
.search-section input, 
.search-section button {
  margin: 0;
  padding: 0;
  border: none;
  outline: none;
  font: inherit; /* Herdar fonte para consistência */
  box-sizing: border-box; /* Inclui padding e bordas no cálculo */
}

/* Estilo da seção de busca */
.search-section {
  position: relative;
  background-color: #f9f9f9;
  padding: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  display: flex; /* Alinha elementos lado a lado */
  align-items: center; /* Centraliza verticalmente */
  gap: 10px; /* Espaço entre os elementos */
}

/* Estilo do campo de entrada */
.search-section input {
  flex: 1; /* Preenche o espaço disponível */
  height: 30px; /* Altura fixa */
  padding: 0 10px; /* Espaçamento interno horizontal */
  border: 1px solid #ccc; /* Borda ao redor */
  border-radius: 4px; /* Bordas arredondadas */
  font-size: 15px; /* Tamanho da fonte */
  line-height: 40px; /* Centraliza o texto verticalmente */
}

/* Estilo do botão */
.search-section button {
  height: 30px; /* Mesma altura do campo de entrada */
  padding: 0 10px; /* Espaçamento interno horizontal */
  background-color: #333;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px; /* Tamanho da fonte consistente */
  cursor: pointer;
  display: flex; /* Flexbox para centralizar o texto */
  align-items: center; /* Centraliza verticalmente */
  justify-content: center; /* Centraliza horizontalmente */
}

/* Hover no botão */
.search-section button:hover {
  background-color: #555;
}


.equipamentos-section div {
  padding: 10px;
  border: 1px solid #ccc;
  margin: 5px;
  cursor: pointer;
  border-radius: 4px;
  background-color: white;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.equipamentos-section div:hover {
  background-color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.equipamentos-section .selected {
  background-color: #d9eaff;
  border-color: #007bff;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
}

.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.form-group textarea {
  height: 50px;
  resize: vertical; /* Permite redimensionar apenas verticalmente */
}

button {
  display: block;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-top: 20px;
  text-align: center;
}

button:hover {
  background-color: #0056b3;
}

/* Expansão de equipamentos */
.equipment-details {
  display: none;
}

.equipment-item.expanded .equipment-details {
  display: block;
  margin-top: 10px;
}

/* Prevenção de elementos fora do topo */
.admin-dashboard {
  position: relative;
}

.dashboard-content {
  flex: 1;
  overflow-y: auto; /* Ativa a rolagem apenas para o conteúdo */
  padding: 15px;
}

.form-field {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-field label {
  margin-bottom: 5px;
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.form-field input,
.form-field textarea,
.form-field select {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  max-width: 400px;
}

.form-field input[type="file"] {
  padding: 5px;
}

button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #0056b3;
}

/* Botão Atualizar */
.btn-atualizar {
  font-size: 18px;
  padding: 12px 24px;
  background-color: #4CAF50;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.btn-atualizar:hover {
  background-color: #45a049;
}

/* Botão Deletar */
.btn-deletar {
  font-size: 12px;
  padding: 6px 12px;
  background-color: #f44336;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
}

.btn-deletar:hover {
  background-color: #e53935;
}

